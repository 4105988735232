"use strict";

import React, { Component } from "react";
import CardContainer from "components/CardContainer";
import MessageSvg from "components/MessageSvg";
import ActionButton from "components/ActionButton";
import { log } from "utils";
import { connect } from "react-redux";
import zChat from "../../vendor/web-sdk";
import PropTypes from "prop-types";

class PrechatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
    this.send = this.send.bind(this);
    this.renderChild = this.renderChild.bind(this);
    this.tags = ["OD2.0"];
    this.previousTags = ["OD2.0"];
    this.removePreviousTags = false;

    const previousTags =
      JSON.parse(localStorage.getItem("zopimTagsPrevious")) || false;
    const currentTags = JSON.parse(localStorage.getItem("zopimTags")) || false;
    if (previousTags && currentTags) {
      this.removePreviousTags =
        JSON.stringify(previousTags) === JSON.stringify(currentTags)
          ? false
          : true;
    }

    if (previousTags) {
      for (const [key, value] of Object.entries(previousTags)) {
        if (key !== "email" && key !== "firstName" && key !== "lastName")
          this.previousTags.push(`${value}`);
      }
    }

    if (currentTags) {
      localStorage.setItem(
        "zopimTagsPrevious",
        localStorage.getItem("zopimTags")
      );

      this.firstName = currentTags.firstName;
      this.lastName = currentTags.lastName;
      this.email = currentTags.email;

      for (const [key, value] of Object.entries(currentTags)) {
        if (key !== "email" && key !== "firstName" && key !== "lastName")
          this.tags.push(`${value}`);
      }
    }
  }

  send(event) {
    event.preventDefault();

    // Use HTML form validation to validate inputs
    const form = this.refs.form;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    const msg = this.refs.message.value;

    // Don't send empty messages
    if (!msg) return;

    if (this.removePreviousTags) {
      zChat.removeTags(this.previousTags, (err) => {
        if (err) return;
      });
      this.previousTags = [];
    }

    zChat.addTags(this.tags, (err) => {
      if (err) return;
    });

    zChat.setVisitorInfo(
      {
        display_name: `${this.firstName} ${this.lastName}`,
        email: this.email,
      },
      (err) => {
        if (err) return;
      }
    );

    zChat.sendChatMsg(msg, (err) => {
      if (err) log("Error sending message");
    });

    this.props.dispatch({
      type: "synthetic",
      detail: {
        type: "visitor_send_msg",
        msg: msg,
      },
    });
  }

  renderChild() {
    return (
      <form ref="form" key="not-sent" className="offline-form">
        <div className="content">
          <div className="section">
            <label className="label">
              Hi, {this.firstName}. How can we help you?
            </label>
            <textarea id="zopim-message" required ref="message" />
          </div>
        </div>
        <div className="button-container">
          <ActionButton
            id="zopim-message-send"
            addClass="button-send"
            label="Send"
            onClick={this.send}
          />
        </div>
      </form>
    );
  }

  render() {
    return (
      <CardContainer
        addClass="offline-card"
        contentAddClass={this.state.sent ? "sent" : ""}
        icon={<MessageSvg />}
      >
        {this.renderChild()}
      </CardContainer>
    );
  }
}

PrechatForm.displayName = "PrechatForm";
PrechatForm.propTypes = {
  onClick: PropTypes.func,
  addClass: PropTypes.string,
};

export default connect()(PrechatForm);
