import config from "../config/base";
const { ENV } = config;

export function log() {
  if (ENV === "dev") {
    console.error.apply(console, arguments); // eslint-disable-line no-console
  }
}

export function isAgent(nick) {
  return nick.startsWith("agent:");
}

export function isTrigger(nick) {
  return nick.startsWith("agent:trigger");
}

export * from "./PersistentStorage";
