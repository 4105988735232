import React from "react";
import { createRoot } from "react-dom/client";
import "@babel/polyfill";
import Widget from "components/Widget";
import { Provider } from "react-redux";
import ChatStore from "stores/ChatStore";
import zChat from "../vendor/web-sdk";
import moment from "moment";

require("moment-timezone");

let root = null;

const helpAndSupportClose = function () {
  root = null;
  zChat.endChat();
};

const updateHours = function () {
  const zendeskDepartmentId = sessionStorage.getItem("zendeskDepartmentId");
  const hours = zChat.getOperatingHours();
  let data = {
    timestamp: Date.now(),
    status: zChat.getAccountStatus(),
    hours: convertSchedule(
      hours.department_schedule[zendeskDepartmentId],
      hours.timezone
    ),
  };

  data = JSON.stringify(data);

  localStorage.setItem("zopimSchedule", data);
  const event = new Event("zopim_schedule_update");
  window.dispatchEvent(event);
};

const convertToTz = (minutesAfterMidnight, sourceTimezone) => {
  const sourceMoment = moment.tz(sourceTimezone);
  const startOfDay = moment(sourceMoment).startOf("day");
  const targetMoment = moment(startOfDay).add(minutesAfterMidnight, "minutes");
  const userTimezone = moment.tz.guess();
  let formattedTime = targetMoment.tz(userTimezone).format("h:mmA z");
  if (moment(formattedTime, "h:mmA z").format("mm") === "00") {
    formattedTime = formattedTime.replace(":00", "");
  }
  return formattedTime;
};

const createScheduleBlock = (hours, timezone) => {
  const dow = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

  const timeBlocks = [];
  for (const [key, value] of Object.entries(hours)) {
    const day = dow[key];
    if (value.length > 0) {
      let times = "";
      let blockCount = 1;
      value.forEach((element) => {
        let start = convertToTz(element.start, timezone).slice(0, -4);
        let end = convertToTz(element.end, timezone);
        times += `${start} - ${end}`;
        if (blockCount != value.length) {
          times += ", ";
          blockCount++;
        }
      });
      timeBlocks.push([day, times]);
    } else {
      timeBlocks.push([day, ""]);
    }
  }
  return timeBlocks;
};

function convertSchedule(hours, timezone) {
  let zopimSchedule = [];
  if (Object.keys(hours).length > 0) {
    const timeBlocks = createScheduleBlock(hours, timezone);
    let startBlockDay = null;
    let startBlockTime = null;

    for (let key = 0; key < timeBlocks.length; key++) {
      const prevDay = timeBlocks[key - 1 >= 0 ? key - 1 : 0][0];
      const thisDay = timeBlocks[key][0];
      const thisTime = timeBlocks[key][1] ? timeBlocks[key][1] : "";
      const isLastDay = key == timeBlocks.length - 1;

      startBlockDay = startBlockDay != null ? startBlockDay : thisDay;
      startBlockTime = startBlockTime != null ? startBlockTime : thisTime;

      if (startBlockTime === thisTime) {
        if (isLastDay && thisTime != "") {
          // Is the last day; complete the current block
          if (startBlockDay === thisDay) {
            zopimSchedule.push(`${thisDay} ${thisTime}`);
          } else {
            zopimSchedule.push(`${startBlockDay} - ${prevDay} ${thisTime}`);
          }
        }
      } else {
        // Times are different; end the current block
        if (startBlockTime != "") {
          if (startBlockDay === prevDay) {
            zopimSchedule.push(`${startBlockDay} ${startBlockTime}`);
          } else {
            zopimSchedule.push(`${startBlockDay}-${prevDay} ${startBlockTime}`);
          }
        }

        if (isLastDay && thisTime != "") {
          zopimSchedule.push(`${thisDay} ${thisTime}`);
        } else {
          // Start a new block
          startBlockDay = thisDay;
          startBlockTime = thisTime;
        }
      }
    }
  } else {
    zopimSchedule.push("Chat hours are unavailable at this time.");
  }
  return zopimSchedule;
}

export const renderWidget = (rootElement, widgElement = null) => {
  const widget = widgElement
    ? widgElement
    : document.getElementById("zopim-widget");
  const widgetContainer = document.getElementById("zopim-widget-container");

  if (!widget && root) {
    root.unmount();
    root = null;
  }
  if (!root && widget) {
    root = createRoot(widget);
  }

  if (widget && !widgetContainer) {
    root.render(
      <Provider store={ChatStore}>
        <Widget />
      </Provider>
    );
    return;
  }
};

function zopiminit() {
  const zendeskAccountId = sessionStorage.getItem("zendeskAccountId");

  zChat.init({
    account_key: zendeskAccountId,
  });

  zChat.on("connection_update", function (event_data) {
    if (event_data === "connected") {
      updateHours();
    }
  });
  
  const observer = new MutationObserver(() => {
    renderWidget(root);
  });

  observer.observe(document, {
    childList: true,
    subtree: true,
  });
}

document.addEventListener("endZopimChat", helpAndSupportClose, false);
document.addEventListener("updateHours", updateHours, false);
document.addEventListener("zopimInit", zopiminit, false);
