"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";

class SendButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button
        className={`send-button ${this.props.addClass || ""}`}
        onClick={this.props.onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
        </svg>
      </button>
    );
  }
}

SendButton.displayName = "SendButton";
SendButton.propTypes = {
  onClick: PropTypes.func,
  addClass: PropTypes.string,
};

export default SendButton;
