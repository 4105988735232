"use strict";

import React, { Component } from "react";
import { connect } from "react-redux";
import Avatar from "components/Avatar";
import ChatMedia from "components/ChatMedia";
import { log, isAgent } from "utils";
import zChat from "../../vendor/web-sdk";
import Autolinker from "autolinker";
import PropTypes from "prop-types";

class ChatMessage extends Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
    this.renderMessagePart = this.renderMessagePart.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.optionOnChange = this.optionOnChange.bind(this);
  }

  getClassName(msg) {
    if (msg.member_type) {
      return msg.member_type;
    } else if (msg.nick) {
      return msg.nick.split(":")[0];
    } else {
      return "";
    }
  }

  optionOnChange(e) {
    const index = e.currentTarget.value,
      msg = this.props.message.options[index];
    zChat.sendChatMsg(msg, (err) => {
      if (err) {
        log("Error occured >>>", err);
        return;
      }
    });
    this.props.dispatch({
      type: "synthetic",
      detail: {
        type: "visitor_send_msg",
        msg,
      },
    });
  }

  renderOptions(options) {
    if (!options || options.length <= 0) return;

    return (
      <div>
        {options.map((option, i) => {
          return (
            <div>
              <input
                type="radio"
                name="option"
                value={i}
                onChange={this.optionOnChange}
              />{" "}
              {option}
            </div>
          );
        })}
      </div>
    );
  }

  decodeHTML(message) {
    var messageText = message.msg;
    var regex = /(\r\n|\r|\n)/g;
    var msg = messageText.replace(regex, "<br>");
    var txt = document.createElement("textarea");
    txt.innerHTML = Autolinker.link(msg);

    return txt.value;
  }

  renderMessagePart(msg) {
    switch (msg.type) {
      case "chat.file":
        return <ChatMedia message={msg} />;
      default:
        this.props.message.msg = this.decodeHTML(msg);

        return (
          <div className="chat-msg">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.message.msg }}
            ></span>
            {this.renderOptions(this.props.message.options)}
          </div>
        );
    }
  }

  render() {
    if (isAgent(this.props.message.nick)) {
      let lastTimestamp = Number(
        localStorage.getItem("zopimAgentLastMessageTimestamp") || 0
      );
      // @description audio path needs to be the path relative to index.html in ondemand 2.0
      const audio = new Audio("./sounds/notification.mp3");

      if (this.props.message.timestamp > lastTimestamp) {
        localStorage.setItem(
          "zopimAgentLastMessageTimestamp",
          this.props.message.timestamp
        );
        const event = new Event("newZopimMessage");
        window.dispatchEvent(event);
        audio.play();
      }
    }
    return (
      <div
        className={`chat-msg-container ${this.getClassName(
          this.props.message
        )} ${this.props.addClass}`}
      >
        <div className="avatar-container">
          <Avatar entity={this.props.agent} />
        </div>
        <div className="chat-msg-wrapper">
          {this.renderMessagePart(this.props.message)}
        </div>
      </div>
    );
  }
}

ChatMessage.displayName = "ChatMessage";
ChatMessage.propTypes = {
  message: PropTypes.object,
  agent: PropTypes.object,
  addClass: PropTypes.string,
};
ChatMessage.defaultProps = {
  message: {
    msg: "",
  },
};

export default connect()(ChatMessage);
